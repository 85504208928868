import { useMemo, useState } from "react";

import {
  AppBar,
  Box,
  Grid,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";

import "./App.css";

import { AppFooter } from "./components/AppFooter";

const drawerWidth = 240;

const App = () => {
  const prefersDarkMode = true;
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline enableColorScheme />

        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(!isDrawerOpen)}
            >
              {isDrawerOpen ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
            <Typography variant="h6" component="div" alignSelf="center">
              HBSC Chia Tools
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          open={isDrawerOpen}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            flexGrow="1"
          >
            <Grid item>
              <Box sx={{ overflow: "auto" }}>
                <List>
                  {/*{["Offer Parser"].map((text, index) => (*/}
                  {/*  <ListItem*/}
                  {/*    button*/}
                  {/*    key={text}*/}
                  {/*    onClick={() => setDrawerOpen(false)}*/}
                  {/*  >*/}
                  {/*    <ListItemText primary={text} />*/}
                  {/*  </ListItem>*/}
                  {/*))}*/}
                </List>
                <Divider />
                {/* <List>
                    {["About us"].map((text, index) => (
                      <ListItem button key={text}>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List> */}
              </Box>
            </Grid>
          </Grid>
        </Drawer>
        <Box
          component="main"
          className="mainContent"
          sx={{ flexGrow: 1, p: 3 }}
        >
          <Toolbar />
          <Grid container direction="column" justifyContent="space-between">
          </Grid>
        </Box>
        <AppFooter />
      </div>
    </ThemeProvider>
  );
};

export default App;
